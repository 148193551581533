import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

export type NavbarContextType = {
  onToggle: () => void
  expanded: boolean
  collapsable: boolean
}

const NavbarContext = createContext<NavbarContextType | null>(null)
NavbarContext.displayName = 'NavbarContext'

export default NavbarContext

export const useNavbar = () => {
  return useContext(NavbarContext) || ({} as NavbarContextType)
}

type NavbarProviderProps = {
  collapsable?: boolean
  children: React.ReactNode
}

export const NavbarProvider = ({
  collapsable = false,
  children,
}: NavbarProviderProps) => {
  const [expanded, setExpanded] = useState(false)
  const onToggle = useCallback((expanded: boolean) => {
    setExpanded(expanded)
  }, [])

  const navbarContext = useMemo<NavbarContextType>(
    () => ({
      onToggle: () => onToggle(!expanded),
      expanded,
      collapsable,
    }),
    [expanded, onToggle, collapsable]
  )

  return (
    <NavbarContext.Provider value={navbarContext}>
      {children}
    </NavbarContext.Provider>
  )
}
