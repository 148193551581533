import React, { useEffect, useMemo, useRef } from 'react'
import {
  Column, IdType, Row, TableOptions, useGlobalFilter, usePagination, useSortBy, useTable
} from 'react-table'

import { PlusCircleIcon, TrashIcon } from '../../Icons'
import CuiButton from '../CuiButton'
import CuiIconButton from '../CuiIconButton'
import { PaginationProps } from '../Pagination/Pagination'
import Spinner from '../Spinner'
import EditableCell from './EditableCell'
import GlobalFilter from './Filters/GlobalFilter'
import styles from './Table.module.scss'
import TablePagination from './TablePagination'

export interface TableProps<T extends Record<string, unknown>>
  extends TableOptions<T>,
    Partial<PaginationProps> {
  columns: Array<Column<T>>
  data: T[]
  striped?: boolean
  bordered?: boolean
  size?: 'default' | 'dense'
  className?: string
  sortable?: boolean
  paginated?: boolean
  editable?: boolean
  enableGlobalFilter?: boolean
  globalFilterLabel?: string
  loading?: boolean
  emptyMessage?: string
  dataKey?: string
  hiddenColumns?: string[]
  activePage?: number
  onEdit?: (index: number, id: IdType<T>, value: any) => void
  onAdd?: () => void
  onDelete?: (index?: number | number[]) => void
  onRowsChanged?: (data: T[]) => void
  renderEditableCell?: (
    index: number,
    value: any
  ) => { [key in IdType<T>]: React.ReactNode }
}

const Table = <T extends Record<string, unknown>>({
  columns: tableColumns,
  data,
  striped = false,
  bordered = false,
  size = 'default',
  sortable = false,
  paginated = false,
  editable = false,
  enableGlobalFilter = false,
  globalFilterLabel,
  loading = false,
  emptyMessage,
  dataKey,
  hiddenColumns,
  className,
  activePage,
  onEdit,
  onAdd,
  onDelete,
  onRowsChanged,
  renderEditableCell,
  ...paginationProps
}: TableProps<T>) => {
  const pageSize = paginated ? paginationProps.pageSize : data.length
  const defaultColumn = editable ? { Cell: EditableCell } : undefined
  const skipPageResetRef = useRef<boolean>()

  const columns = useMemo(() => {
    const deleteColumn = editable &&
      onDelete && {
        Header: () => null,
        id: 'delete',
        Cell: ({ row }: any) => (
          <CuiIconButton
            variant="text"
            size="small"
            onClick={() => {
              skipPageResetRef.current = true
              onDelete(row.index)
            }}
            className={styles.deleteButton}
            aria-label="Ta bort"
          >
            <TrashIcon />
          </CuiIconButton>
        ),
        disableGlobalFilter: true,
      }

    if (deleteColumn) return [...tableColumns, deleteColumn]

    return tableColumns
  }, [editable, onDelete, tableColumns])

  const initialState =
    hiddenColumns || pageSize
      ? {
          ...(hiddenColumns && { hiddenColumns }),
          ...(pageSize && { pageSize }),
        }
      : undefined

  const instance = useTable<T>(
    {
      columns,
      data,
      defaultColumn,
      initialState,
      onEdit,
      renderEditableCell,
      disableSortBy: !sortable,
      autoResetPage: !skipPageResetRef.current,
      autoResetRowState: !skipPageResetRef.current,
      autoResetSortBy: !skipPageResetRef.current,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    state,
    setGlobalFilter,
    rows,
  } = instance

  useEffect(() => {
    onRowsChanged?.(rows.map((row) => row.original))
  }, [onRowsChanged, rows])

  useEffect(() => {
    if (paginated) return

    state.pageSize = data?.length || state.pageSize
  }, [paginated, data, state])

  useEffect(() => {
    if (activePage) {
      gotoPage(activePage - 1)
    }
  }, [activePage, gotoPage])

  useEffect(() => {
    skipPageResetRef.current = false
  })

  const getRowProps = (row: Row<T>) => {
    const defaultProps = {}

    if (!dataKey) return defaultProps
    if (!row.values.hasOwnProperty(dataKey)) return defaultProps

    return {
      ...defaultProps,
      key: row.values[dataKey],
    }
  }

  return (
    <div className={styles.tableContainer}>
      {enableGlobalFilter && (
        <GlobalFilter
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
          label={globalFilterLabel}
        />
      )}
      <table
        {...getTableProps()}
        className={[
          styles.table,
          styles[`table--${size}`],
          striped && styles['table--striped'],
          bordered && styles['table--bordered'],
          className,
        ].join(' ')}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  {sortable && (
                    <span
                      className={styles.sortIcon}
                      dangerouslySetInnerHTML={{
                        __html: column.isSorted
                          ? column.isSortedDesc
                            ? '&#8595;'
                            : '&#8593;'
                          : '',
                      }}
                    ></span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps(getRowProps(row))}>
                {row.cells.map((cell) => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
          {loading && (
            <tr>
              <td colSpan={columns.length} className={styles.tableSpinner}>
                <Spinner centered />
              </td>
            </tr>
          )}
          {!loading && !data.length && emptyMessage && (
            <tr>
              <td colSpan={columns.length}>{emptyMessage}</td>
            </tr>
          )}
        </tbody>
        {editable && onAdd && (
          <tfoot>
            <tr>
              <td colSpan={columns.length}>
                <CuiButton
                  variant="text"
                  onClick={() => {
                    skipPageResetRef.current = true
                    onAdd()
                  }}
                  type="button"
                >
                  <PlusCircleIcon className={styles.addRowButtonIcon} /> Lägg
                  till rad
                </CuiButton>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
      {paginated && (
        <TablePagination
          instance={instance}
          activePageIndex={activePage ? activePage - 1 : undefined}
          {...paginationProps}
        />
      )}
    </div>
  )
}

export default Table
