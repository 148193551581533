import 'regenerator-runtime/runtime'
import React, { useState } from 'react'
import { useAsyncDebounce } from 'react-table'
import Input from '../../../Input'

export type GlobalFilterProps = {
  globalFilter: any
  setGlobalFilter: (filterValue: any) => void
  label?: string
}

const GlobalFilter = ({
  globalFilter,
  setGlobalFilter,
  label = 'Search records...',
}: GlobalFilterProps) => {
  const [value, setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <Input
      type="search"
      variant="outlined"
      value={value || ''}
      onChange={(e) => {
        setValue(e.target.value)
        onChange(e.target.value)
      }}
      placeholder={label}
      aria-label={label}
    />
  )
}

export default GlobalFilter
