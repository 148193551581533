import { default as ReactModal } from 'react-modal'

import { useKeyboardEvent } from '@react-hookz/web'

import CuiButton from '../CuiButton'
import Modal from '../Modal'
import styles from './ConfirmDialog.module.scss'
import { useConfirmContext } from './ConfirmDialogContext'

export type ConfirmDialogProps = Omit<ReactModal.Props, 'isOpen'>

/**
 * Confirm Dialog component.
 *
 * Add the ConfirmDialog component to your application (preferably to the root component).
 * Wrap it and the rest of the application with the ConfirmDialogProvider.
 *
 * Use the useConfirm hook and call the confirm function to trigger the confirm dialog. Wait for the boolean response and proceed accordingly.
 * Pass to the confirm function the content of the confirm dialog and optionally the button texts.
 */
const ConfirmDialog = ({ ...modalProps }: ConfirmDialogProps) => {
  const { onConfirm, onCancel, confirmState } = useConfirmContext()

  useKeyboardEvent('Enter', (ev) => {
    if (!confirmState.show) return

    ev.preventDefault()
    onConfirm()
  })

  return (
    <Modal
      {...modalProps}
      isOpen={confirmState.show}
      title={confirmState.options?.title}
      showCloseButton={false}
      overlayClassName={styles.overlay}
      bodyClassName={styles.body}
      onRequestClose={onCancel}
      shouldCloseOnOverlayClick={false}
    >
      {confirmState.content}

      <footer className={styles.footer}>
        <CuiButton variant="text" onClick={onCancel}>
          {confirmState.options?.cancelButtonText || 'Avbryt'}
        </CuiButton>
        <CuiButton onClick={onConfirm}>
          {confirmState.options?.confirmButtonText || 'OK'}
        </CuiButton>
      </footer>
    </Modal>
  )
}

export default Object.assign(ConfirmDialog, {
  setAppElement: ReactModal.setAppElement,
})
