import React from 'react'
import classNames from 'classnames'

import styles from './Nav.module.scss'
import { useNavbar } from '../Navbar/NavbarContext'
import NavLink from './Link'

export type NavProps = {
  id: string
  children: React.ReactNode
} & React.HTMLAttributes<HTMLElement>

const Nav = ({ id, children, className, ...props }: NavProps) => {
  const { expanded, collapsable } = useNavbar()

  return (
    <nav
      {...props}
      id={id}
      className={classNames(
        styles.nav,
        collapsable && styles[`nav--collapsable`],
        className
      )}
      data-visible={expanded}
    >
      <ul className={classNames(styles.navList)}>{children}</ul>
    </nav>
  )
}

export default Object.assign(Nav, {
  Link: NavLink,
})
