import React from 'react'
import { CellProps } from 'react-table'
import Input from '../../Input'

const EditableCell = <T extends Record<string, unknown>>({
  value: initialValue,
  row: { index },
  column: { Header, id },
  onEdit,
  renderEditableCell,
}: CellProps<T>) => {
  const [value, setValue] = React.useState(initialValue)

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
  }

  const onBlur = () => {
    onEdit?.(index, id, value)
  }

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return renderEditableCell ? (
    renderEditableCell(index, initialValue)[id]
  ) : (
    <Input
      value={value}
      variant="flat"
      placeholder={`Skriv ${Header?.toLocaleString().toLocaleLowerCase()}...`}
      onChange={onChange}
      onBlur={onBlur}
    />
  )
}

export default EditableCell
