import classNames from 'classnames'
import React from 'react'

import styles from './InputAdornment.module.scss'

export type InputAdornmentProps = React.HTMLAttributes<HTMLSpanElement> & {
  children: React.ReactNode
  color?: string
}

const InputAdornment = ({
  color,
  className,
  children,
  ...props
}: InputAdornmentProps) => {
  return (
    <span
      {...props}
      className={classNames(styles.InputAdornment, className)}
      style={{ '--adornment-color': color } as React.CSSProperties}
    >
      {children}
    </span>
  )
}

export default InputAdornment
