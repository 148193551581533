import React from 'react'

import Spinner from '../Spinner'
import styles from './Button.module.scss'

export type ButtonVariants =
  | 'primary'
  | 'default'
  | 'transparentGreen'
  | 'transparentGreenDark'
  | 'invertedGreen'
  | 'invertedGreenDark'
  | 'greyLight'
  | 'transparentGrey'
  | 'textGreen'
  | 'greenLight'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string
  variant?: ButtonVariants
  noBorderRadius?: boolean
  round?: boolean
  icon?: React.ReactNode
  size?: 'full' | 'medium' | 'small' | 'compact'
  className?: string
  href?: string
  loading?: boolean
}

/**
 * @deprecated Use CuiButton component instead.
 *
 * Button component.
 * Extends all attributes of a regular HTML button element.
 * Any children passed to it is ignored.<br>
 * Additional info: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button
 */
const Button = ({
  text,
  noBorderRadius,
  variant = 'default',
  round = false,
  icon,
  size,
  className = '',
  type = 'button',
  href,
  loading = false,
  ...btnProps
}: ButtonProps) => {
  const dataTestId = (btnProps as any)['data-testid'] || 'button'

  let variantStyle: string = ''
  switch (variant) {
    case 'primary':
      variantStyle = `${styles['Button--green']}`
      break
    case 'greyLight':
      variantStyle = styles['Button--grayLight4']
      break
    case 'greenLight':
      variantStyle = styles['Button--greenLight2']
      break
    case 'transparentGrey':
      variantStyle = styles['Button--transparentGray']
      break
    case 'default':
      break
    default:
      variantStyle = styles[`Button--${variant}`]
      break
  }

  const commonClasses = [
    variantStyle,
    styles.button,
    size ? styles[`Button--${size}`] : '',
    (loading || icon) && text ? styles['Button--iconAndText'] : '',
    !noBorderRadius && styles['Button--radius'],
    round ? styles['Button--round'] : '',
  ]

  return href ? (
    <a
      href={href}
      role="button"
      className={[...commonClasses, styles['Button-link'], className].join(' ')}
      data-testid={`${dataTestId}-link`}
    >
      {text}
    </a>
  ) : (
    <button
      {...btnProps}
      type={type}
      className={[...commonClasses, className].join(' ')}
      data-testid={dataTestId}
    >
      {loading && (
        <i className={[styles['Button-icon']].join(' ')}>
          <Spinner size="mini" color="inherit" />
        </i>
      )}
      {icon && <i className={styles['Button-icon']}>{icon}</i>}
      {text && text}
    </button>
  )
}
export default Button
