import classNames from 'classnames'
import _uniqueId from 'lodash/uniqueId'
import React, { useRef } from 'react'

import { CheckIcon, CrossIcon, LockIcon } from '../../Icons'
import HelperText from '../HelperText'
import { useInput } from '../Input/Input.hooks'
import InputAdornment from '../Input/InputAdornment'
import { useAutoHeight } from './Textarea.hooks'
import styles from './Textarea.module.scss'

export type TextareaProps =
  React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    /** The label for the textarea */
    label: string
    /** If true, the component will be displayed in a success state */
    isValid?: boolean
    /** If true, the component will be displayed in an error state */
    error?: boolean
    /** Helper text content */
    helperText?: string
    /** Override or extend the styles applied to the component */
    containerClassName?: string
  }

/**
 * The height of the textarea is adjusted automatically relative to its content
 *
 * Extends all attributes of a regular HTML Textarea element.<br>
 * Additional info: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/textarea
 */
const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      label,
      isValid,
      error,
      helperText,
      containerClassName,
      className,
      style: styleProp,
      id: idProp,
      ...props
    }: TextareaProps,
    ref
  ) => {
    const textareaRef = useRef<HTMLTextAreaElement | null>(null)
    const { value, shrinkLabel, handleChange, handleFocus, handleBlur } =
      useInput({
        ...props,
      })
    const { height } = useAutoHeight({
      ref: textareaRef,
      value: value as string,
    })

    const id = idProp || _uniqueId('textarea-')
    const style = {
      ...styleProp,
      '--cui-textarea-height': height,
    } as React.CSSProperties

    return (
      <div
        className={classNames(
          styles.container,
          isValid && styles['container--valid'],
          error && styles['container--error'],
          props.disabled && styles['container--disabled'],
          containerClassName
        )}
        data-testid="textarea-container"
      >
        <div className={classNames(styles.base)}>
          <textarea
            {...props}
            ref={(node) => {
              textareaRef.current = node
              if (typeof ref === 'function') {
                ref(node)
              } else if (ref) {
                ref.current = node
              }
            }}
            id={id}
            value={value}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={classNames(styles.textarea, className)}
            aria-invalid={error}
            aria-describedby={helperText ? `${id}-helperText` : undefined}
            disabled={props.disabled}
            style={style}
          ></textarea>
          {isValid && (
            <InputAdornment
              className={styles.icon}
              aria-hidden="true"
              color="#00A142"
            >
              <CheckIcon data-testid="valid-icon" />
            </InputAdornment>
          )}
          {error && (
            <InputAdornment
              className={styles.icon}
              aria-hidden="true"
              color="#FF3300"
            >
              <CrossIcon data-testid="error-icon" />
            </InputAdornment>
          )}
          {props.disabled && (
            <InputAdornment className={styles.icon} aria-hidden="true">
              <LockIcon data-testid="disabled-icon" />
            </InputAdornment>
          )}
        </div>
        <label
          htmlFor={id}
          className={classNames(
            styles.label,
            shrinkLabel && styles['label--shrink']
          )}
          data-testid="label"
        >
          {label}
        </label>
        {helperText && (
          <HelperText className={styles.helperText} id={`${id}-helperText`}>
            {helperText}
          </HelperText>
        )}
      </div>
    )
  }
)

export default Textarea
