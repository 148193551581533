import React from 'react'
import classNames from 'classnames'
import IconButton from '../../IconButton'
import { IconButtonProps } from '../../IconButton/IconButton'

import styles from './NavbarToggle.module.scss'
import { useNavbar } from '../NavbarContext'

export type NavbarToggleProps = {
  'aria-controls': string
  label?: string
} & React.ButtonHTMLAttributes<HTMLButtonElement>

const NavbarToggle = ({
  'aria-controls': controls,
  label = 'visa/dölj meny',
  className,
  ...props
}: NavbarToggleProps) => {
  const { onToggle, expanded, collapsable } = useNavbar()

  if (!collapsable) return null

  return (
    <IconButton
      aria-label={label}
      aria-controls={controls}
      aria-expanded={expanded}
      className={classNames(styles.navbarToggle, className)}
      onClick={onToggle}
      {...(props as IconButtonProps)}
    >
      <div className={styles['navbarToggle-icon']}>
        <span></span>
      </div>
    </IconButton>
  )
}

export default NavbarToggle
