import React from 'react'
import classNames from 'classnames'
import {
  CuiPrefixProps,
  CuiPrefixRefForwardingComponent,
} from '../../../Utilities/types/CuiPrefixProps'

import styles from './NavLink.module.scss'
import { useNavbar } from '../../Navbar/NavbarContext'

export type NavLinkProps = {
  href?: string
} & CuiPrefixProps &
  React.HTMLAttributes<HTMLElement>

const NavLink: CuiPrefixRefForwardingComponent<'a', NavLinkProps> =
  React.forwardRef<HTMLElement, NavLinkProps>(
    ({ as, className, ...props }, ref) => {
      const { collapsable } = useNavbar()
      const Component = as || 'a'

      return (
        <li>
          <Component
            {...props}
            ref={ref}
            className={classNames(
              styles.navLink,
              collapsable && styles[`navLink--collapsable`],
              className
            )}
          />
        </li>
      )
    }
  )

export default NavLink
