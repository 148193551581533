import React from 'react'
import styles from './Radio.module.scss'
import _uniqueId from 'lodash/uniqueId'

export interface RadioProps {
  value: any
  label: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  variant?: 'default' | 'button'
  name?: string
  checked?: boolean
  disabled?: boolean
  icon?: React.ReactNode
}

const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      value,
      label,
      onChange,
      variant = 'default',
      name,
      checked,
      disabled,
      icon,
      ...rest
    }: RadioProps,
    ref
  ) => {
    const id = _uniqueId('radio-')

    return (
      <div className={[styles.Radio, styles[`Radio--${variant}`]].join(' ')}>
        <input
          {...rest}
          ref={ref}
          id={id}
          className={styles['Radio-input']}
          type="radio"
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={(e) => (onChange ? onChange(e) : null)}
        />
        <label htmlFor={id} className={styles['Radio-label']}>
          {label}
        </label>
        {variant === 'button' && icon && (
          <i className={styles['Radio-icon']}>{icon}</i>
        )}
      </div>
    )
  }
)

export default Radio
