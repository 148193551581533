import classNames from 'classnames'
import React from 'react'

import Button from '../../CuiButton'
import { ButtonProps } from '../../CuiButton/CuiButton'
import { useDropdown } from '../DropdownContext'
import styles from './DropdownToggle.module.scss'

export type DropdownToggleProps = {
  'aria-controls': string
} & ButtonProps &
  React.ButtonHTMLAttributes<HTMLButtonElement>

const DropdownToggle = ({
  onClick,
  'aria-controls': controls,
  className,
  ...props
}: DropdownToggleProps) => {
  const { onToggle, expanded } = useDropdown()

  const handleToggle = (e: React.MouseEvent<HTMLButtonElement>) => {
    onToggle()
    onClick?.(e)
  }

  return (
    <Button
      {...(props as ButtonProps)}
      aria-expanded={expanded}
      aria-controls={controls}
      onClick={handleToggle}
      className={classNames(
        styles.dropdownToggle,
        props.variant && styles[`dropdownToggle--${props.variant}`],
        className
      )}
    />
  )
}

export default DropdownToggle
