import React from 'react'
import classNames from 'classnames'
import {
  CuiPrefixProps,
  CuiPrefixRefForwardingComponent,
} from '../../../Utilities/types/CuiPrefixProps'

import styles from './DropdownItem.module.scss'

export type DropdownItemProps = {
  href?: string
} & CuiPrefixProps &
  React.HTMLAttributes<HTMLElement>

const DropdownItem: CuiPrefixRefForwardingComponent<'a', DropdownItemProps> =
  React.forwardRef<HTMLElement, DropdownItemProps>(
    ({ as, className, ...props }, ref) => {
      const Component = as || 'a'

      return (
        <Component
          {...props}
          ref={ref}
          className={classNames(styles.dropdownItem, className)}
        />
      )
    }
  )

export default DropdownItem
