import classNames from 'classnames'
import React from 'react'

import styles from './Heading.module.scss'

type Level = 1 | 2 | 3 | 4 | 5 | 6
type Size = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl'
type Variant = 'primary' | 'secondary'
type Alignment = 'left' | 'right' | 'center'

export type HeadingProps = {
  level?: Level
  size?: Size
  variant?: Variant
  align?: Alignment
} & React.HTMLAttributes<HTMLHeadingElement>

const Heading = ({
  level = 2,
  size,
  variant = 'primary',
  align,
  className,
  ...props
}: HeadingProps) => {
  const Component = `h${level}` as const

  return (
    <Component
      {...props}
      className={classNames(
        styles.heading,
        size && styles[`heading--${size}`],
        styles[`heading--fontFamily-${variant}`],
        align && styles[`heading--align-${align}`],
        className
      )}
    />
  )
}

export default Heading
