import React from 'react'
import styles from './Spinner.module.scss'

export interface SpinnerProps {
  className?: string
  size?: 'mini' | 'default'
  centered?: boolean
  color?: 'primary' | 'inherit'
}

const Spinner = ({
  className = '',
  size = 'default',
  centered = false,
  color = 'primary',
}: SpinnerProps) => {
  return (
    <div
      className={[
        styles.spinner,
        styles[`spinner--${size}`],
        centered ? styles[`spinner--centered`] : undefined,
        styles[`spinner--${color}`],
        className,
      ].join(' ')}
      data-testid="spinner"
    >
      <div className={styles.spinnerIcon}>
        <div data-testid="spinner-ball"></div>
        <div data-testid="spinner-ball"></div>
        <div data-testid="spinner-ball"></div>
      </div>
    </div>
  )
}

export default Spinner
