import { HTMLAttributes } from 'react'
import classNames from 'classnames'
import styles from './Container.module.scss'

export type ContainerProps = {
  fluid?: boolean
} & HTMLAttributes<HTMLElement>

const Container = ({
  fluid,
  children,
  className,
  ...props
}: ContainerProps) => {
  return (
    <div
      {...props}
      className={classNames(
        styles.container,
        fluid && styles['container-fluid'],
        className
      )}
    >
      {children}
    </div>
  )
}

export default Container
