import { useEffect, useState } from 'react'

type Direction = 'up' | 'down'

/**
 * Get the scrolling direction
 *
 * @param threshold The scrolling threshold before registering a change to the scroll direction. Defaults to 0
 * @returns {Direction} The scroll direction or undefined if no scroll has been registered
 */
export const useScrollDirection = (threshold = 0) => {
  const [direction, setDirection] = useState<Direction>()

  useEffect(() => {
    let lastScroll = 0

    const handleScroll = () => {
      const currentScroll = window.scrollY

      const scrollDifference = currentScroll - lastScroll

      if (scrollDifference > threshold) {
        setDirection('down')
      } else if (scrollDifference < -1 * threshold) {
        setDirection('up')
      }

      lastScroll = currentScroll
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [threshold])

  return direction
}
