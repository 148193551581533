import _uniqueId from 'lodash/uniqueId'
import React from 'react'

import { CheckIcon, CrossIcon, LockIcon } from '../../Icons'
import CuiIconButton from '../CuiIconButton'
import HelperText from '../HelperText'
import { useInput } from './Input.hooks'
import styles from './Input.module.scss'
import { InputType } from './Input.type'
import InputAdornment from './InputAdornment'

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string
  error?: boolean
  isValid?: boolean
  type?: InputType
  variant?: 'contained' | 'outlined' | 'flat'
  helperText?: string
  startAdornment?: React.ReactNode
  endAdornment?: React.ReactNode
  rounded?: boolean
  containerClassName?: string
  clearButton?: boolean
}

/**
 * Input component.
 * Extends all attributes of a regular HTML Input element.<br>
 * Additional info: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input
 */
const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      error = false,
      isValid,
      type = 'text',
      variant = 'contained',
      helperText = '',
      startAdornment,
      endAdornment,
      rounded,
      containerClassName,
      clearButton = false,
      value: inputValue,
      ...inputProps
    }: InputProps,
    ref
  ) => {
    const {
      shrinkLabel,
      value,
      hasValue,
      handleChange,
      handleFocus,
      handleBlur,
      clear,
    } = useInput({
      ...inputProps,
      value: inputValue,
      type,
      startAdornment,
    })

    const id = _uniqueId('input-')

    return (
      <div
        className={[
          styles.InputContainer,
          label && styles['InputContainer--labeled'],
          error ? styles['InputContainer--error'] : undefined,
          isValid && styles['InputContainer--valid'],
          inputProps.disabled && styles['InputContainer--disabled'],
          containerClassName,
        ].join(' ')}
        data-testid="input-container"
      >
        <div
          className={[
            styles.InputBase,
            styles[`Input--${variant}`],
            hasValue && styles['InputBase--hasValue'],
            startAdornment && styles.InputBaseStartAdornment,
            endAdornment && styles.InputBaseEndAdornment,
            rounded && styles[`Input--rounded`],
            inputProps.placeholder && styles['InputBase--withPlaceholder'],
          ].join(' ')}
        >
          {startAdornment && <InputAdornment>{startAdornment}</InputAdornment>}
          <input
            value={value}
            {...inputProps}
            onChange={handleChange}
            id={id}
            ref={ref}
            type={type}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={[
              styles.Input,
              hasValue ? styles.hasValue : '',
              inputProps.placeholder ? styles['Input--withPlaceholder'] : '',
            ].join(' ')}
            aria-invalid={error}
            aria-describedby={helperText ? `${id}-helperText` : undefined}
          />
          {clearButton && (
            <InputAdornment>
              <CuiIconButton
                title="Rensa"
                aria-label="Rensa"
                className={styles.InputClearButton}
                onClick={clear}
                variant="text"
              >
                <CrossIcon />
              </CuiIconButton>
            </InputAdornment>
          )}
          {isValid && (
            <InputAdornment aria-hidden="true" color="#00A142">
              <CheckIcon data-testid="valid-icon" />
            </InputAdornment>
          )}
          {error && (
            <InputAdornment aria-hidden="true" color="#FF3300">
              <CrossIcon data-testid="error-icon" />
            </InputAdornment>
          )}
          {inputProps.disabled && (
            <InputAdornment aria-hidden="true">
              <LockIcon data-testid="disabled-icon" />
            </InputAdornment>
          )}
          {endAdornment && <InputAdornment>{endAdornment}</InputAdornment>}
        </div>
        {label && (
          <label
            htmlFor={id}
            className={[
              styles.InputLabel,
              shrinkLabel ? styles['InputLabel--shrink'] : '',
            ].join(' ')}
          >
            {label}
          </label>
        )}
        {helperText && (
          <HelperText
            className={styles.InputHelperText}
            id={`${id}-helperText`}
          >
            {helperText}
          </HelperText>
        )}
      </div>
    )
  }
)

export default Input
