import classNames from 'classnames'
import uniqueId from 'lodash/uniqueId'
import React from 'react'

import { CrossIcon } from '../../Icons'
import IconButton from '../IconButton'
import styles from './Notice.module.scss'

export type Severity = 'fatal' | 'error' | 'warning' | 'info'

export type NoticeProps = {
  children: React.ReactNode
  id?: string
  severity?: Severity
  onClose?: (id: string) => void
  className?: string
}

const Notice = ({
  id,
  severity = 'info',
  onClose,
  children,
  className,
}: NoticeProps) => {
  id = id || uniqueId('notice-')

  return (
    <div
      className={classNames(
        styles.notice,
        styles[`notice--${severity}`],
        onClose && styles['notice--dismissable'],
        className
      )}
      role="alert"
      data-id={id}
    >
      {children}
      {onClose && (
        <IconButton
          onClick={() => onClose(id!)}
          className={styles.noticeCloseButton}
          aria-label="Stäng"
          noPadding
        >
          <CrossIcon />
        </IconButton>
      )}
    </div>
  )
}

export default Notice
