import classNames from 'classnames'
import React, { CSSProperties } from 'react'

import BaseDropdown from './Base'
import styles from './Dropdown.module.scss'
import { DropdownProvider } from './DropdownContext'
import DropdownItem from './Item'
import DropdownMenu from './Menu'
import DropdownToggle from './Toggle'

type Alignment = 'right' | 'left' | 'center'

export type DropdownProps = {
  align?: Alignment
  onToggle?: (isOpen?: boolean) => void
  onClose?: () => void
  show?: boolean
} & React.HTMLAttributes<HTMLElement>

const Dropdown = ({
  align = 'left',
  onToggle,
  onClose,
  show = false,
  className,
  children,
  ...props
}: DropdownProps) => {
  const getPositionStyles = (align: Alignment) => {
    let positionStyles = {}

    switch (align) {
      case 'left':
        positionStyles = {
          ...positionStyles,
          '--cui-dropdown-position-left': 0,
          '--cui-dropdown-position-right': 'auto',
        }
        break
      case 'right':
        positionStyles = {
          ...positionStyles,
          '--cui-dropdown-position-left': 'auto',
          '--cui-dropdown-position-right': 0,
        }
        break
      case 'center':
        positionStyles = {
          ...positionStyles,
          '--cui-dropdown-position-left': '50%',
          '--cui-dropdown-translateX': '-50%',
        }
        break
      default:
        break
    }

    return positionStyles
  }

  const positionStyles = getPositionStyles(align)

  return (
    <DropdownProvider show={show} onClose={onClose} onToggle={onToggle}>
      <BaseDropdown
        {...props}
        className={classNames(styles.dropdown, className)}
        style={{ ...positionStyles } as CSSProperties}
      >
        {children}
      </BaseDropdown>
    </DropdownProvider>
  )
}

export default Object.assign(Dropdown, {
  Toggle: DropdownToggle,
  Menu: DropdownMenu,
  Item: DropdownItem,
})
