import classNames from 'classnames'
import React from 'react'

import { CuiPrefixRefForwardingComponent } from '../../Utilities/types/CuiPrefixProps'
import { ButtonProps, Variant } from '../CuiButton/CuiButton'
import styles from './CuiIconButton.module.scss'

export type IconButtonProps = Omit<ButtonProps, 'variant'> &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    variant?: 'outlined' | Variant
  }

const IconButton: CuiPrefixRefForwardingComponent<'a', IconButtonProps> =
  React.forwardRef<HTMLButtonElement, IconButtonProps>(
    (
      {
        variant = 'contained',
        color = 'primary',
        size = 'medium',
        pill = false,
        children,
        className,
        as,
        ...buttonProps
      }: IconButtonProps,
      ref
    ) => {
      const Component = as || 'button'

      return (
        <Component
          ref={ref}
          className={classNames(
            styles.iconButton,
            styles[`iconButton--${variant}`],
            styles[`iconButton--${color}`],
            styles[`iconButton--${size}`],
            pill && styles[`iconButton--pill`],
            className
          )}
          {...buttonProps}
        >
          <span aria-hidden="true">{children}</span>
        </Component>
      )
    }
  )

export default IconButton
