import React, { createContext, useContext, useMemo, useRef, useState } from 'react'

export type ConfirmOptions = {
  title?: string
  confirmButtonText?: string
  cancelButtonText?: string
}

export type ConfirmDialogContextType = {
  onConfirm: () => void
  onCancel: () => void
  confirm: (
    content: React.ReactNode,
    options?: ConfirmOptions
  ) => Promise<boolean>
  confirmState: {
    show: boolean
    content: React.ReactNode
    options?: ConfirmOptions
  }
}

const ConfirmDialogContext =
  createContext<ConfirmDialogContextType | null>(null)
ConfirmDialogContext.displayName = 'ConfirmDialogContext'

export default ConfirmDialogContext

export const useConfirm = () => {
  return { confirm: useConfirmContext().confirm }
}

export const useConfirmContext = () => {
  return useContext(ConfirmDialogContext) || ({} as ConfirmDialogContextType)
}

type ConfirmDialogProviderProps = {
  children: React.ReactNode
}

export const ConfirmDialogProvider = ({
  children,
}: ConfirmDialogProviderProps) => {
  const [show, setShow] = useState(false)
  const [content, setContent] = useState<React.ReactNode>()
  const [options, setOptions] = useState<ConfirmOptions>()

  const resolveCallback = useRef<
    (value: boolean | PromiseLike<boolean>) => void
  >(() => {})
  const confirmDialogContext = useMemo<ConfirmDialogContextType>(
    () => ({
      onConfirm: () => {
        setShow(false)
        resolveCallback.current(true)
      },
      onCancel: () => {
        setShow(false)
        resolveCallback.current(false)
      },
      confirm: (content: React.ReactNode, options?: ConfirmOptions) => {
        setShow(true)
        setContent(content)
        setOptions(options)

        return new Promise((resolve, _reject) => {
          resolveCallback.current = resolve
        })
      },
      confirmState: {
        show,
        content,
        options,
      },
    }),
    [content, options, show]
  )

  return (
    <ConfirmDialogContext.Provider value={confirmDialogContext}>
      {children}
    </ConfirmDialogContext.Provider>
  )
}
