import React from 'react'

import styles from './IconButton.module.scss'

type Variant = 'default' | 'outlined' | 'contained'
type Size = 'small' | 'medium' | 'large'
type Color = 'default' | 'primary'

const baseCssClass = 'IconButton'

export interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: Variant
  color?: Color
  size?: Size
  noPadding?: boolean
  className?: string
}

/**
 * @deprecated Use CuiIconButton component instead.
 */
const IconButton = ({
  variant,
  size,
  color,
  noPadding = false,
  children,
  className = '',
  type = 'button',
  ...buttonProps
}: IconButtonProps) => {
  return (
    <button
      type={type}
      className={[
        styles.IconButton,
        variant && styles[`${baseCssClass}--${variant}`],
        size && styles[`${baseCssClass}--${size}`],
        getColorStyle(variant, color),
        noPadding && styles[`${baseCssClass}--noPadding`],
        className,
      ].join(' ')}
      {...buttonProps}
    >
      <span aria-hidden="true">{children}</span>
    </button>
  )
}

const getColorStyle = (variant?: Variant, color?: Color) => {
  switch (color) {
    case 'primary':
      if (!variant || variant === 'default') {
        return styles[`${baseCssClass}--${color}`]
      }
      return styles[`${baseCssClass}--${variant}-${color}`]
    default:
      return ''
  }
}

export default IconButton
