import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'

export type DropdownContextType = {
  onToggle: () => void
  expanded: boolean
  onClose: () => void
}

const DropdownContext = createContext<DropdownContextType | null>(null)
DropdownContext.displayName = 'DropdownContext'

export default DropdownContext

export const useDropdown = () => {
  return useContext(DropdownContext) || ({} as DropdownContextType)
}

type DropdownProviderProps = {
  children: React.ReactNode
  onToggle?: (isOpen?: boolean) => void
  onClose?: () => void
  show?: boolean
}

export const DropdownProvider = ({
  children,
  onToggle,
  onClose,
  show = false,
}: DropdownProviderProps) => {
  const [expanded, setExpanded] = useState(show)
  const handleToggle = useCallback((expanded: boolean) => {
    setExpanded(expanded)
  }, [])

  useEffect(() => {
    setExpanded(show)
  }, [show])

  const dropdownContext = useMemo<DropdownContextType>(
    () => ({
      onToggle: () => {
        handleToggle(!expanded)
        onToggle?.(!expanded)
      },
      expanded,
      onClose: () => {
        handleToggle(false)
        onClose?.()
      },
    }),
    [expanded, handleToggle, onClose, onToggle]
  )

  return (
    <DropdownContext.Provider value={dropdownContext}>
      {children}
    </DropdownContext.Provider>
  )
}
