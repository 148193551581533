import React, { useRef } from 'react'

import { useClickOutside } from '@react-hookz/web'

import { useDropdown } from '../DropdownContext'

export type BaseDropdownProps = React.HTMLAttributes<HTMLElement>

const BaseDropdown = ({ children, ...props }: BaseDropdownProps) => {
  const { expanded, onClose } = useDropdown()
  const ref = useRef(null)

  useClickOutside(ref, () => {
    if (!expanded) return

    onClose()
  })

  return (
    <div {...props} ref={ref}>
      {children}
    </div>
  )
}

export default BaseDropdown
