import classNames from 'classnames'
import React from 'react'

import styles from './HelperText.module.scss'

export type HelperTextProps = React.HTMLAttributes<HTMLParagraphElement> & {}

/**
 * HelperText component.
 * Show a small helper text for inputs or other places
 * Inherits the color of its parent
 */
const HelperText = ({ children, className, ...props }: HelperTextProps) => (
  <p {...props} className={classNames(styles.helperText, className)}>
    {children}
  </p>
)

export default HelperText
