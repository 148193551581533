import React from 'react'
import classNames from 'classnames'
import {
  CuiPrefixProps,
  CuiPrefixRefForwardingComponent,
} from '../../Utilities/types/CuiPrefixProps'

import styles from './Link.module.scss'

export type LinkProps = {
  href?: string
} & CuiPrefixProps &
  React.HTMLAttributes<HTMLElement>

const Link: CuiPrefixRefForwardingComponent<'a', LinkProps> = React.forwardRef<
  HTMLElement,
  LinkProps
>(({ as, className, ...props }, ref) => {
  const Component = as || 'a'

  return (
    <Component
      {...props}
      ref={ref}
      className={classNames(styles.link, className)}
    />
  )
})

export default Link
