import { useEffect, useState } from 'react'

import { InputType } from './Input.type'

type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>

type InputProps = React.InputHTMLAttributes<HTMLInputElement>

export type Props = (TextareaProps | InputProps) & {
  startAdornment?: React.ReactNode
  type?: InputType
}

export const useInput = ({
  autoFocus,
  placeholder,
  value: inputValue,
  onFocus,
  onBlur,
  onChange,
  type,
  startAdornment,
}: Props) => {
  const [value, setValue] = useState<typeof inputValue>(inputValue || '')
  const [hasValue, setHasValue] = useState(false)
  const [hasFocus, setHasFocus] = useState(!!autoFocus)
  const [shrinkLabel, setShrinkLabel] = useState(false)

  useEffect(() => {
    setValue(inputValue)
  }, [inputValue])

  useEffect(() => {
    const valueAsString = value?.toString()
    const hasValue = valueAsString && valueAsString?.length > 0
    setHasValue(!!hasValue)
    const hasValueOrPlaceholder =
      hasValue || placeholder || startAdornment || type === 'date'

    setShrinkLabel(!!(hasFocus || hasValueOrPlaceholder))
  }, [hasFocus, placeholder, startAdornment, type, value])

  const handleFocus = (
    e: React.FocusEvent<HTMLTextAreaElement & HTMLInputElement>
  ) => {
    setHasFocus(true)
    onFocus?.(e)
  }
  const handleBlur = (
    e: React.FocusEvent<HTMLTextAreaElement & HTMLInputElement>
  ) => {
    setHasFocus(false)
    onBlur?.(e)
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement & HTMLInputElement>
  ) => {
    onChange?.(e)
    setValue(e.target.value)
  }

  const clear = () => {
    setValue('')
  }

  return {
    value,
    hasValue,
    shrinkLabel,
    handleChange,
    handleFocus,
    handleBlur,
    clear,
  }
}
