import classNames from 'classnames'
import React from 'react'

import {
  CuiPrefixProps, CuiPrefixRefForwardingComponent
} from '../../Utilities/types/CuiPrefixProps'
import IconButton from '../CuiIconButton'
import Spinner from '../Spinner'
import styles from './CuiButton.module.scss'

export type Variant = 'contained' | 'text'
export type Color = 'primary' | 'secondary' | 'tertiary' | 'inherit'
export type Size = 'small' | 'medium' | 'large'
export type Shape = 'rounded' | 'pill'

export type BaseButtonProps = {
  variant?: Variant
  /** Main color of the button. Only applicable for 'contained' buttons. */
  color?: Color
  /** Adjust the padding of the button. Only applicable for 'contained' buttons. */
  size?: Size
  /** Shape of the 'contained' button */
  // shape?: Shape
  pill?: boolean
} & CuiPrefixProps

export type ButtonProps = BaseButtonProps & {
  /** If set to true, the button will be as wide as its container */
  fluid?: boolean
  /** If set to true, disables the button and displays a loading spinner next to the text */
  loading?: boolean
  icon?: React.ReactNode
} & React.ButtonHTMLAttributes<HTMLButtonElement>

/**
 * Button component.
 * Extends all attributes of a regular HTML button element.
 * Additional info: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button
 */
const Button: CuiPrefixRefForwardingComponent<'button', ButtonProps> =
  React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
      {
        variant = 'contained',
        color = 'primary',
        size = 'medium',
        pill = false,
        fluid = false,
        loading = false,
        icon,
        className,
        children,
        disabled,
        as,
        ...props
      }: ButtonProps,
      ref
    ) => {
      const Component = as || 'button'

      if (icon && !children) {
        return (
          <IconButton
            {...props}
            ref={ref}
            className={className}
            disabled={disabled || loading}
            variant={variant}
            color={color}
            as={as}
          >
            {icon}
          </IconButton>
        )
      }

      return (
        <Component
          ref={ref}
          className={classNames(
            styles.button,
            styles[`button--${variant}`],
            styles[`button--${color}`],
            styles[`button--${size}`],
            loading && styles[`button--loading`],
            fluid && styles[`button--fluid`],
            pill && styles[`button--pill`],
            className
          )}
          disabled={disabled || loading}
          {...props}
        >
          {children}
          {icon && (
            <i className={styles.buttonIcon} aria-hidden="true">
              {icon}
            </i>
          )}
          {loading && (
            <i className={classNames(styles.buttonIcon)} aria-hidden={true}>
              <Spinner size="mini" color="inherit" />
            </i>
          )}
        </Component>
      )
    }
  )

export default Button
