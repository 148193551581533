import classNames from 'classnames'
import React from 'react'

import { useScrollDirection } from '../../hooks/useScrollDirection'
import {
  CuiPrefixProps, CuiPrefixRefForwardingComponent
} from '../../Utilities/types/CuiPrefixProps'
import Container from '../Container'
import NavbarBrand from './Brand'
import styles from './Navbar.module.scss'
import { NavbarProvider } from './NavbarContext'
import NavbarToggle from './Toggle'

export type NavbarProps = {
  /**
   * The general visual variant of the Navbar.
   */
  variant?: 'primary' | 'dark' | 'light'
  /**
   * Set if the `Container` within the navbar should be fluid (take the whole width) or have a set max width
   */
  fluid?: boolean
  /**
   * Collapse the child `Nav` on small devices and toggle its visibility with a "hamburger menu" button
   */
  collapsable?: boolean
  /**
   * Create a fixed navbar along the top or bottom of the screen, that scolls with the page.
   * Use in combination with `autoHide` to show/hide the navbar on scroll
   */
  fixed?: 'top' | 'bottom'
  /** Add a shadow to the navbar */
  shadowed?: boolean
  /**
   * Hide/show the navbar on scroll down/up
   * Has no effect unless used with the `fixed` property
   */
  autoHide?: boolean
  /** The scrolling distance in px needed before the navbar is shown/hidden */
  autoHideThreshold?: number
  children: React.ReactNode
} & CuiPrefixProps &
  React.HTMLAttributes<HTMLElement>

const Navbar: CuiPrefixRefForwardingComponent<'header', NavbarProps> =
  React.forwardRef<HTMLElement, NavbarProps>(
    (
      {
        fluid = false,
        variant = 'primary',
        collapsable = true,
        fixed,
        shadowed = false,
        children,
        className,
        autoHide = false,
        autoHideThreshold = 0,
        as = 'header',
        ...props
      }: NavbarProps,
      ref
    ) => {
      const scrollDirection = useScrollDirection(autoHideThreshold)

      const Component = as

      return (
        <NavbarProvider collapsable={collapsable}>
          <Component
            {...props}
            ref={ref}
            className={classNames(
              styles.navbar,
              styles[`navbar--${variant}`],
              fixed && styles[`navbar--fixed-${fixed}`],
              shadowed && styles[`navbar--shadowed`],
              fixed && autoHide && styles.navbarAutoHide,
              fixed &&
                autoHide &&
                scrollDirection &&
                styles[`scroll-${scrollDirection}`],
              className
            )}
          >
            <Container fluid={fluid} className={styles.navbarContainer}>
              {children}
            </Container>
          </Component>
        </NavbarProvider>
      )
    }
  )

export default Object.assign(Navbar, {
  Toggle: NavbarToggle,
  Brand: NavbarBrand,
})
