import React from 'react'
import { Toaster as ToastContainer } from 'react-hot-toast'

/**
 * Toaster component.
 * A wrapper around react-hot-toast.
 * Additional info: https://react-hot-toast.com/
 */
const Toaster = () => {
  const toastOptions = {
    success: {
      duration: 4000,
    },
  }

  return <ToastContainer position="bottom-right" toastOptions={toastOptions} />
}

export default Toaster

export { toast } from 'react-hot-toast'
