import React from 'react'
import ReactPaginate from 'react-paginate'
import { ChevronLeftIcon, ChevronRightIcon } from '../../Icons'
import styles from './Pagination.module.scss'

type Color = 'default' | 'primary'

export interface PaginationProps {
  totalCount: number
  color?: Color
  pageSize?: number
  initialPage?: number
  activePageIndex?: number
  pageRangeDisplayed?: number
  marginRangeDisplayed?: number
  onPageChange?: (activePage: number) => void
}

const Pagination = ({
  totalCount,
  color = 'default',
  pageSize = 10,
  initialPage = 1,
  activePageIndex,
  pageRangeDisplayed = 4,
  marginRangeDisplayed = 1,
  onPageChange,
}: PaginationProps) => {
  const pageCount = Math.ceil(totalCount / pageSize)

  const getAriaLabel = (pageIndex: number, selected: boolean) =>
    `Sida ${pageIndex} ${selected ? 'är aktiv' : ''}`.trim()

  const handlePageChange = ({ selected }: { selected: number }) => {
    onPageChange?.(selected + 1)
  }

  return (
    <ReactPaginate
      pageCount={pageCount}
      initialPage={initialPage - 1}
      pageRangeDisplayed={pageRangeDisplayed}
      marginPagesDisplayed={marginRangeDisplayed}
      containerClassName={[
        styles.pagination,
        color && styles[`pagination--${color}`],
      ].join(' ')}
      pageLinkClassName={styles.paginationButton}
      breakLinkClassName={styles.paginationButton}
      activeLinkClassName={styles.active}
      previousLabel={<ChevronLeftIcon />}
      previousLinkClassName={styles.prevButton}
      nextLabel={<ChevronRightIcon />}
      nextLinkClassName={styles.nextButton}
      disabledClassName={styles.disabled}
      ariaLabelBuilder={getAriaLabel}
      onPageChange={(selectedItem) => handlePageChange(selectedItem)}
      forcePage={activePageIndex}
    />
  )
}

export default Pagination
