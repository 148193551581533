import classNames from 'classnames'
import uniqueId from 'lodash/uniqueId'
import React from 'react'

import HelperText from '../HelperText'
import styles from './Checkbox.module.scss'

export type CheckboxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string | React.ReactNode
  helperText?: string
  hasError?: boolean
  containerClassName?: string
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      label,
      helperText,
      hasError,
      defaultChecked,
      containerClassName,
      className,
      ...inputProps
    }: CheckboxProps,
    ref
  ) => {
    const id = uniqueId('checkbox-')

    return (
      <div
        className={classNames(
          styles.checkboxContainer,
          hasError ? styles.checkboxError : '',
          containerClassName
        )}
      >
        <div className={styles.checkbox}>
          <input
            type="checkbox"
            ref={ref}
            id={id}
            className={classNames(styles.checkboxInput, className)}
            checked={defaultChecked}
            aria-describedby={helperText ? `${id}-helperText` : undefined}
            {...inputProps}
          />

          <label className={styles.checkboxLabel} htmlFor={id}>
            {label}
          </label>
        </div>

        {helperText && (
          <HelperText
            className={styles.checkboxHelperText}
            id={`${id}-helperText`}
          >
            {helperText}
          </HelperText>
        )}
      </div>
    )
  }
)

export default Checkbox
