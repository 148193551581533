import classNames from 'classnames'
import { default as ReactModal } from 'react-modal'

import { ChevronLeftIcon, CrossIcon } from '../..'
import CuiIconButton from '../CuiIconButton'
import Heading from '../Heading'
import styles from './Modal.module.scss'

export type Anchor = 'center' | 'top' | 'right' | 'bottom' | 'left'

export type ModalProps = ReactModal.Props & {
  anchor?: Anchor
  bodyClassName?: string
  title?: string
  goBack?: () => void
  showCloseButton?: boolean
}

const Modal = ({
  anchor = 'center',
  bodyClassName,
  overlayClassName,
  onRequestClose,
  title,
  className,
  goBack,
  children,
  showCloseButton = true,
  ...modalProps
}: ModalProps) => (
  <ReactModal
    {...modalProps}
    onRequestClose={onRequestClose}
    className={classNames(
      styles.Modal,
      styles[`Modal--${anchor}`],
      goBack && styles.ModalMultiPage,
      className
    )}
    overlayClassName={classNames(styles['Modal-overlay'], overlayClassName)}
    bodyOpenClassName={styles.open}
  >
    {(title || goBack || showCloseButton) && (
      <header className={styles.header}>
        <CuiIconButton
          variant="outlined"
          onClick={goBack}
          aria-label="Gå tillbaka"
          color="inherit"
          className={styles.headerBackButton}
          pill
          size="small"
          disabled={!!!goBack}
          aria-hidden={!!!goBack ? 'true' : undefined}
        >
          <ChevronLeftIcon />
        </CuiIconButton>
        <Heading align="center" size="xs" className={styles.headerTitle}>
          {title}
        </Heading>
        {showCloseButton && (
          <CuiIconButton
            variant="text"
            onClick={onRequestClose}
            data-dismiss="modal"
            aria-label="Stäng"
            size="small"
            color="inherit"
            className={styles.headerCloseButton}
          >
            <CrossIcon />
          </CuiIconButton>
        )}
      </header>
    )}
    <div className={classNames(styles.body, bodyClassName)}>{children}</div>
  </ReactModal>
)

export default Object.assign(Modal, {
  setAppElement: ReactModal.setAppElement,
})
