import classNames from 'classnames'
import React from 'react'
import { useDropdown } from '../DropdownContext'

import styles from './DropdownMenu.module.scss'

export type DropdownMenuProps = React.HTMLAttributes<HTMLDivElement>

const DropdownMenu = ({ className, children, ...props }: DropdownMenuProps) => {
  const { expanded } = useDropdown()

  return (
    <div
      {...props}
      className={classNames(styles.dropdownMenu, className)}
      data-visible={expanded}
    >
      {children}
    </div>
  )
}

export default DropdownMenu
