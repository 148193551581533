import React from 'react'
import classNames from 'classnames'

import styles from './NavbarBrand.module.scss'
import {
  CuiPrefixProps,
  CuiPrefixRefForwardingComponent,
} from '../../../Utilities/types/CuiPrefixProps'

export type NavbarBrandProps = {
  href?: string
} & CuiPrefixProps &
  React.HTMLAttributes<HTMLElement>

const NavbarBrand: CuiPrefixRefForwardingComponent<'a', NavbarBrandProps> =
  React.forwardRef<HTMLElement, NavbarBrandProps>(
    ({ as, className, ...props }, ref) => {
      const Component = as || (props.href ? 'a' : 'span')

      return (
        <Component
          {...props}
          ref={ref}
          className={classNames(styles.navbarBrand, className)}
        />
      )
    }
  )

export default NavbarBrand
