import { ForwardedRef, MutableRefObject, useLayoutEffect, useState } from 'react'

type Props = {
  ref?: ForwardedRef<HTMLTextAreaElement>
  value: string
}

export const useAutoHeight = ({ ref, value }: Props) => {
  const [height, setHeight] = useState('auto')

  useLayoutEffect(() => {
    if (!ref) return

    const textareaElement = (ref as MutableRefObject<HTMLTextAreaElement>)
      .current
    if (!textareaElement) return

    const scrollHeight = textareaElement.scrollHeight / 16

    setHeight(`${scrollHeight}rem`)
  }, [ref, value])

  return { height }
}
