import React from 'react'
import { TableInstance } from 'react-table'
import Pagination from '../../Pagination'

interface TablePaginationProps<T extends Record<string, unknown>> {
  instance: TableInstance<T>
}

const TablePagination = <T extends Record<string, unknown>>({
  instance,
  ...paginationProps
}: TablePaginationProps<T>) => {
  const {
    state: { pageSize },
    gotoPage,
  } = instance
  const rowCount = instance.rows.length

  return (
    <Pagination
      totalCount={rowCount}
      pageSize={pageSize}
      initialPage={1}
      onPageChange={(pageNumber) => gotoPage(pageNumber - 1)}
      {...paginationProps}
    />
  )
}

export default TablePagination
